import { AfterContentInit, ChangeDetectorRef, Component, DestroyRef, HostBinding } from '@angular/core';
import { ProcessingHttpInterceptorStateService } from '../services/processing-http-interceptor-state.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'bk-http-processing-loader',
  standalone: true,
  imports: [],
  templateUrl: './http-processing-loader.component.html',
  styleUrl: './http-processing-loader.component.scss'
})
export class HttpProcessingLoaderComponent implements AfterContentInit {
  @HostBinding('class.visible') private isVisible = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private processingHttpInterceptorStateService: ProcessingHttpInterceptorStateService,
    private destroyRef: DestroyRef,
  ) { }

  ngAfterContentInit(): void {
    this.processingHttpInterceptorStateService
      .openRequests$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(0), // prevent NG0100: ExpressionChangedAfterItHasBeenCheckedError
      )
      .subscribe(i => this.updateVisibility(i))
  }

  private async updateVisibility(values: unknown[]) {
    if (this.isVisible && !values.length) {
      this.isVisible = false;
    } else if (!this.isVisible && !!values.length) {
      this.isVisible = true;
    }
  }
}
