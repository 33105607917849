import { Component, HostBinding, HostListener } from '@angular/core';
import { GenericControlValueAccessorDirective } from '../../services/generic-control-value-acessor.directive';

@Component({
  selector: 'bk-hamburguer-menu',
  standalone: true,
  imports: [],
  templateUrl: './hamburguer-menu.component.html',
  styleUrl: './hamburguer-menu.component.scss'
})
export class HamburguerMenuComponent extends GenericControlValueAccessorDirective<boolean> {
  @HostBinding('class.open')
  private get isOpen() {
    return this.value;
  }

  @HostListener('click')
  private toggleMenu() {
    this.writeValue(!this.value);
  }
}
