<a i18n class="primary" routerLink="/">
  <img class="logo" src="assets/svg/logo.svg" i18n-alt alt="Festaza" />
</a>
<div class="mobile tablet">
  <bk-dropdown [options]="languageOptions" [ngModel]="language" (ngModelChange)="setLanguage($event)" />
  <bk-hamburguer-menu [formControl]="isOpen" />
</div>
<bk-actionables class="desktop separators w-100" [actionables]="actionables" />
<div class="desktop footer">
  <span class="secondary" i18n>All the memories of your</span>
  <span class="secondary">
    <span i18n>event in a single place</span> &nbsp;
    <i class="icon-festaza-star primary vertical-align-middle"> </i>
  </span>
</div>
<div class="desktop">
  <bk-dropdown [options]="languageOptions" [ngModel]="language" (ngModelChange)="setLanguage($event)" />
</div>
