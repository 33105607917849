import { Component, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { HttpProcessingLoaderComponent } from './http-processing-loader/http-processing-loader.component';
import { LocaleManagerService } from './services/locale-manager.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'bk-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MainMenuComponent,
    HttpProcessingLoaderComponent,
  ],
  providers: [
    { provide: DEFAULT_CURRENCY_CODE, useValue: LocaleManagerService.defaultCurrencyCode() }
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(
    titleService: Title,
  ) {
    titleService.setTitle($localize`Festaza`)
  }
}
