import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { Component, DestroyRef } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ActionablesComponent } from '../actionables/actionables.component';
import { UserContextService } from '../services/user-context.service';
import { HamburguerMenuComponent } from './hamburguer-menu/hamburguer-menu.component';
import { mainMenuActionables } from './main-menu-actionables';
import { MobileModalComponent } from './mobile-modal/mobile-modal.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, merge } from 'rxjs';
import { DropdownComponent, IIdLabel } from '../dropdown/dropdown.component';
import { LocaleManagerService } from '../services/locale-manager.service';
import { EnumHelper } from '../services/enum-helper';
import { CommonModule } from '@angular/common';
import { AvailableLocales } from '../openapi';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'bk-main-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ActionablesComponent,
    HamburguerMenuComponent,
    PortalModule,
    OverlayModule,
    DropdownComponent,
  ],
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss'
})
export class MainMenuComponent {
  actionables = mainMenuActionables(this.router, this.userContext, this.navigationService);
  overlayRef?: OverlayRef;
  isOpen = new FormControl<boolean>(false, { nonNullable: true });
  languageOptions: IIdLabel<string>[] = Object.entries(EnumHelper.languageOptions).map(([key, value]) => ({
    id: key,
    label: value,
  }));
  language = LocaleManagerService.getLocale();

  constructor(
    private navigationService: NavigationService,
    private userContext: UserContextService,
    private router: Router,
    private destroyRef: DestroyRef,
    public overlay: Overlay,
  ) {
    this.isOpen.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => this.toggle(value))
  }

  toggle(isOpen: boolean) {
    if (isOpen) {
      const positionStrategy = this.overlay.position()
        .global()
        .bottom();
      this.overlayRef = this.overlay.create({
        positionStrategy,
        hasBackdrop: true,
        disposeOnNavigation: true,
        width: '100%'
      });
      const mainMenuMobileComponent = new ComponentPortal(MobileModalComponent);
      this.overlayRef.attach(mainMenuMobileComponent);

      merge(
        this.overlayRef.backdropClick(),
        this.router.events.pipe(takeUntilDestroyed(this.destroyRef), filter(ev => ev instanceof NavigationEnd)),
      ).subscribe(() => this.disposeOverlay());
    } else {
      this.disposeOverlay();
    }
  }

  setLanguage(locale: AvailableLocales) {
    if (locale === LocaleManagerService.getLocale()) {
      return;
    }
    LocaleManagerService.saveLocale(locale);
  }

  private disposeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
      this.isOpen.setValue(false);
    }
  }
}
