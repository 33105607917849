import { Component } from '@angular/core';
import { UserContextService } from '../../services/user-context.service';
import { Router } from '@angular/router';
import { mainMenuActionables } from '../main-menu-actionables';
import { ActionablesComponent } from '../../actionables/actionables.component';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'bk-mobile-modal',
  standalone: true,
  imports: [ActionablesComponent],
  templateUrl: './mobile-modal.component.html',
  styleUrl: './mobile-modal.component.scss'
})
export class MobileModalComponent {
  actionables = mainMenuActionables(this.router, this.userContext, this.navigationService);

  constructor(
    private navigationService: NavigationService,
    private userContext: UserContextService,
    private router: Router,
  ) { }
}
